
import http from "../../../../common/services/http";
import { defineComponent, computed, ref, reactive, onMounted, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";

	export default defineComponent({
		setup(props) {
			const form = ref({});

			const farms = ref([]);
			const farm = ref(0);

			const farmName = computed(() => {
				const n = farms.value?.find((f: any) => f.id == farm.value);

				return n?.["name"];
			});

			const varieties = ref([]);

			const search = ref("");
			const assignment = reactive({
				name: "",
				code: "",
			});

			const customers = ref([]);
			const user_id = ref(0);
			const customer = computed(() => {
				return customers.value?.find((c: any) => c.id == user_id.value);
			});

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);
			const day = ref(0);
			const date = ref(new Date());

    const prod = ref(1);

			const today = computed(() => new Date());
			const week = ref({
				start: new Date(),
				end: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000),
			});
			const masks = ref({
				input: "YYYY-MM-DD",
			});

			function getDates(start: any, stop: any) {
				const dates = [];
				let currentDate = moment(start);
				const stopDate = moment(stop);
				while (currentDate <= stopDate) {
					dates.push(moment(currentDate).format("YYYY-MM-DD"));
					currentDate = moment(currentDate).add(1, "days");
				}

				return dates;
			}

			function fD(d: any, f = "PPPP") {
				return format(d, f);
			}

			function fetchFarms() {
				processing.value = true;
				http.get(`/api/farms?fetch=all`).then((res) => {
					farms.value = res.data;
					processing.value = false;
				});
			}

			function fetchCustomers() {
				processing.value = true;
				http.get(`/api/users?role=customer&fetch=all`).then((res) => {
					customers.value = res.data;
					processing.value = false;
				});
			}

			async function fetchPicks(f: any) {
				const res = await http.get(
					`/api/picks?farm_id=${f}&start=${fD(
						week.value.start,
						"yyyy-MM-dd"
					)}&end=${fD(week.value.end, "yyyy-MM-dd")}`
				);

				return res.data.data;
			}

			function fetchVarieties(id: any) {
				processing.value = true;

				fetchPicks(id).then((ps: any) => {
					http.get(`/api/varieties?fetch=all`).then((res) => {
						const a = res.data;
						varieties.value = a;

						const dates = getDates(
							week.value.start,
							week.value.end
						);

						form.value = dates.reduce((o, d) => {
							const p: any = ps.find(
								(or: any) => or.date == d
							);

							return {
								...o,
								[d]: a.reduce(
									(r: any, v: any) => ({
										...r,
										[v.id]:
											{
											projected:
												(p?.quantities as any)[
													v.id
												]['projected'] || 0,
										}
									}),
									{}
								),
							};
						}, {});

						processing.value = false;
					});
				});
			}

			function searchVarieties() {
				fetchPicks(user_id.value).then((ps: any) => {
					http.get(
						`/api/varieties?user_id=${user_id.value}&s=${search.value}`
					).then((res) => {
						const a = res.data.data;
						varieties.value = a;

						const dates = getDates(
							week.value.start,
							week.value.end
						);

						form.value = dates.reduce((o, d) => {
							const p: any = ps.find(
								(or: any) => or.date == d
							);

							return {
								...o,
								[d]: a.reduce(
									(r: any, v: any) => ({
										...r,
										[v.id]:
											{
											projected:
												(p?.quantities as any)[
													v.id
												]['projected'] || 0,

            block: "",
            shift: "day",
            planned: 0,
            used: 0,
            packed: 0,
            waste: 0,
            actual: 0,
            target: 0,
            rejects: 0,
            fpackout: 0,
            tpackout: 0,
            comments: ""
										}
									}),
									{}
								),
							};
						}, {});
					});
				});
			}

			function savePicks() {
				http.post("/api/picks", {
					farm_id: farm.value,
					picks: form.value,
					start: week.value.start,
					end: week.value.end,
				})
					.then((res) => {
						if (res.data.success) {
							created.value = true;
						}
					})
					.finally(() => {
						fetchVarieties(farm.value);
					});
			}

			function updateAssignment(assignment: any) {
				assignment._method = 'PUT'
				http.post(`/api/varieties/${assignment.id}`, assignment)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchVarieties(user_id.value);
					});
			}

			function deleteAssignment(id: any) {
				http.post(`/api/varieties/${id}`, { _method: "DELETE" }).then((res) => {
					fetchVarieties(user_id.value);
				});
			}

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setProd(n: 0) {
      prod.value = n;
    }

			onMounted(() => {
				fetchFarms();
			});

			watch(
				() => week.value.end,
				(v: any) => {
					fetchVarieties(user_id.value);
				}
			);

			function updateForm() {
				fetchVarieties(user_id.value);
			}

			return {
				fetchVarieties,
				processing,
				search,
				searchVarieties,
				varieties,
				savePicks,
				created,
				assignment,
				updateAssignment,
				updated,
				deleteAssignment,
				fD,
				today,
				week,
				masks,
				getDates,
				setDay,
				day,
				date,
				form,
				farm,
				farms,
				farmName,
				customers,
				customer,
				user_id,
				updateForm,
      prod,
      setProd
			};
		},
	});
