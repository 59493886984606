<template>
  <div class="ng-content-wrap">
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>
              Week of
              <b>{{ formatDate(week.start) }}</b> -
              <b>{{ formatDate(week.end) }}</b>
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
						<li v-if="farms.length">
							<div class="input-group">
								<div class="input-group-prepend">
									<span
										class="input-group-text bg-primary text-white font-weight-bold"
									>
										Farm
									</span>
								</div>
								<Multiselect
									:options="farms"
									v-model="farm"
									trackBy="name"
									label="name"
									valueProp="id"
									:searchable="true"
									placeholder="Type to search..."
									@change="fetchVarieties"
								/>
							</div>
						</li>
						<li v-if="farm">
							<a
								href="#pickDates"
								data-toggle="modal"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<em class="icon ni ni-plus-c"></em>
								<span>
									<span class="d-none d-sm-inline-block">
										Select
									</span>
									Week
								</span>
							</a>
							<div
								class="modal fade"
								tabindex="-1"
								id="pickDates"
							>
								<div class="modal-dialog" role="document">
									<div
										class="modal-content justify-content-center"
									>
										<button
											id="hideNewForm"
											class="close"
											data-dismiss="modal"
											aria-label="Close"
										>
											<em
												class="icon ni ni-cross text-white"
											></em>
										</button>

										<div class="modal-header bg-primary">
											<h5 class="modal-title text-white">
												Select Date Range
											</h5>
										</div>

										<div
											class="modal-body row justify-content-center"
										>
											<div
												class="col-md-7 justify-content-center text-center mb-1"
											>
												<v-date-picker
													value="range"
													v-model="week"
													is-range
													@drag="updateForm"
												/>
											</div>
											<div class="col-md-5 text-center">
												<h5>Starting on</h5>
												<p>
													{{ fD(week.start) }}
												</p>

												<h5>Ending on</h5>
												<p>
													{{ fD(week.end) }}
												</p>

												<button
													class="btn btn-outline-primary float-right"
													data-dismiss="modal"
												>
													<span>
														Use Selected Dates
													</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered px-3" v-else>
        <div class="rows">
          <ul class="nav nav-tabs">
            <li
              class="nav-item"
              v-for="(d, i) in getDates(week.start, week.end)"
              :key="i"
            >
              <a
                class="nav-link"
                :class="{ active: i == day }"
                data-toggle="tab"
                :href="`#day${i}`"
                @click="setDay(i, d)"
              >
                {{ formatDate(new Date(d), "E, do MMM") }}
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              v-for="(d, i) in getDates(week.start, week.end)"
              :key="i"
              class="tab-pane"
              :id="`day${i}`"
              :class="{ active: i == day }"
            >
              <div class="ng-block ng-block-lg">
                <div class="ng-block-head px-4">
                  <div class="ng-block-head-content">
                    <h5 class="ng-block-title d-none">
                      {{ formatDate(new Date(d)) }}
                    </h5>

                    <div class="ng-block-des d-print-none">
                      <p>
                        <b class="float-left">
                          {{ formatDate(new Date(d)) }}
                        </b>
                        <b class="float-right">{{ farm }} (Farm)</b>
                      </p>
                    </div>
                  </div>
                </div>
                <form class="card w-100" @submit.prevent="savePick">
                  <div class="card-body row">
                    <div class="col-md-3">
                      <ul
                        class="nav nav-tabs flex-column"
                        id="myTab"
                        role="tablist"
                      >
                        <li
                          class="nav-item"
                          v-for="(p, i) in products"
                          :key="i"
                        >
                          <a
                            class="nav-link"
                            :class="{
                              active: p.id == prod
                            }"
                            :id="`tab${p.id}`"
                            data-toggle="tab"
                            :href="`#pack${p.id}`"
                            role="tab"
                            :aria-controls="`pack${p.id}`"
                            :aria-selected="p.id == prod"
                            @click="setProd(p.id)"
                          >
                            {{ p.name }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-9 tab-content" id="myTabContent">
                      <div
                        v-for="(p, i) in products"
                        :key="i"
                        class="tab-pane fade show"
                        :class="{
                          active: p.id == prod
                        }"
                        :id="`pack${p.id}`"
                        role="tabpanel"
                        :aria-labelledby="`tab${p.id}`"
                      >
                        <div class="card card-bordered" v-if="form[p.id]">
                          <div class="card-header">
                            <h4 class="card-title">
                              {{ p.name }}
                            </h4>
                          </div>
                          <div class="card-body row">
                            <div class="form-group col-md-8">
                              <label>
                                Select Block
                              </label>
                              <input
                                class="form-control"
                                v-model="form[p.id]['block']"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label>
                                Shift
                              </label>
                              <input
                                class="form-control"
                                v-model="form[p.id]['shift']"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label>
                                Planned Planting Packout
                              </label>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['planned']"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label>Used</label>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['used']"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label>
                                Packed
                              </label>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['packout']"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label>
                                Waste
                              </label>

                              <div class="input-group">
                                <input
                                  class="form-control"
                                  type="number"
                                  v-model="form[p.id]['waste']"
                                />
                                <div class="input-group-append">
                                  <span class="input-group-text">20%</span>
                                </div>
                              </div>
                            </div>

                            <div class="form-group col-md-4">
                              <label>
                                Rejects
                              </label>
                              <div class="input-group">
                                <input
                                  class="form-control"
                                  type="number"
                                  v-model="form[p.id]['rejects']"
                                />
                                <div class="input-group-append">
                                  <span class="input-group-text">20%</span>
                                </div>
                              </div>
                            </div>

                            <div class="form-group col-md-4">
                              <label>
                                Actual Packout
                              </label>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['actual']"
                              />
                            </div>

                            <div class="form-group col-md-4">
                              <label>
                                Target Packout Packhouse
                              </label>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['packout']"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label>
                                Farm Packout
                              </label>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['fpackout']"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label> Target Farm Packout</label>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['tpackout']"
                              />
                            </div>
                            <div class="form-group col-md-12">
                              <label>
                                Comments
                              </label>
                              <textarea
                                class="form-control"
                                rows="1"
                                v-model="form[p.id]['comments']"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <button class="btn btn-primary float-right">
                      Save
                      <span class="pl-1 d-none d-sm-block">
                        for
                        {{ formatDate(new Date(d), "do MMM") }}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../common/services/http";
import { defineComponent, computed, ref, reactive, onMounted, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";

	export default defineComponent({
		setup(props) {
			const form = ref({});

			const farms = ref([]);
			const farm = ref(0);

			const farmName = computed(() => {
				const n = farms.value?.find((f: any) => f.id == farm.value);

				return n?.["name"];
			});

			const varieties = ref([]);

			const search = ref("");
			const assignment = reactive({
				name: "",
				code: "",
			});

			const customers = ref([]);
			const user_id = ref(0);
			const customer = computed(() => {
				return customers.value?.find((c: any) => c.id == user_id.value);
			});

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);
			const day = ref(0);
			const date = ref(new Date());

    const prod = ref(1);

			const today = computed(() => new Date());
			const week = ref({
				start: new Date(),
				end: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000),
			});
			const masks = ref({
				input: "YYYY-MM-DD",
			});

			function getDates(start: any, stop: any) {
				const dates = [];
				let currentDate = moment(start);
				const stopDate = moment(stop);
				while (currentDate <= stopDate) {
					dates.push(moment(currentDate).format("YYYY-MM-DD"));
					currentDate = moment(currentDate).add(1, "days");
				}

				return dates;
			}

			function fD(d: any, f = "PPPP") {
				return format(d, f);
			}

			function fetchFarms() {
				processing.value = true;
				http.get(`/api/farms?fetch=all`).then((res) => {
					farms.value = res.data;
					processing.value = false;
				});
			}

			function fetchCustomers() {
				processing.value = true;
				http.get(`/api/users?role=customer&fetch=all`).then((res) => {
					customers.value = res.data;
					processing.value = false;
				});
			}

			async function fetchPicks(f: any) {
				const res = await http.get(
					`/api/picks?farm_id=${f}&start=${fD(
						week.value.start,
						"yyyy-MM-dd"
					)}&end=${fD(week.value.end, "yyyy-MM-dd")}`
				);

				return res.data.data;
			}

			function fetchVarieties(id: any) {
				processing.value = true;

				fetchPicks(id).then((ps: any) => {
					http.get(`/api/varieties?fetch=all`).then((res) => {
						const a = res.data;
						varieties.value = a;

						const dates = getDates(
							week.value.start,
							week.value.end
						);

						form.value = dates.reduce((o, d) => {
							const p: any = ps.find(
								(or: any) => or.date == d
							);

							return {
								...o,
								[d]: a.reduce(
									(r: any, v: any) => ({
										...r,
										[v.id]:
											{
											projected:
												(p?.quantities as any)[
													v.id
												]['projected'] || 0,
										}
									}),
									{}
								),
							};
						}, {});

						processing.value = false;
					});
				});
			}

			function searchVarieties() {
				fetchPicks(user_id.value).then((ps: any) => {
					http.get(
						`/api/varieties?user_id=${user_id.value}&s=${search.value}`
					).then((res) => {
						const a = res.data.data;
						varieties.value = a;

						const dates = getDates(
							week.value.start,
							week.value.end
						);

						form.value = dates.reduce((o, d) => {
							const p: any = ps.find(
								(or: any) => or.date == d
							);

							return {
								...o,
								[d]: a.reduce(
									(r: any, v: any) => ({
										...r,
										[v.id]:
											{
											projected:
												(p?.quantities as any)[
													v.id
												]['projected'] || 0,

            block: "",
            shift: "day",
            planned: 0,
            used: 0,
            packed: 0,
            waste: 0,
            actual: 0,
            target: 0,
            rejects: 0,
            fpackout: 0,
            tpackout: 0,
            comments: ""
										}
									}),
									{}
								),
							};
						}, {});
					});
				});
			}

			function savePicks() {
				http.post("/api/picks", {
					farm_id: farm.value,
					picks: form.value,
					start: week.value.start,
					end: week.value.end,
				})
					.then((res) => {
						if (res.data.success) {
							created.value = true;
						}
					})
					.finally(() => {
						fetchVarieties(farm.value);
					});
			}

			function updateAssignment(assignment: any) {
				assignment._method = 'PUT'
				http.post(`/api/varieties/${assignment.id}`, assignment)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchVarieties(user_id.value);
					});
			}

			function deleteAssignment(id: any) {
				http.post(`/api/varieties/${id}`, { _method: "DELETE" }).then((res) => {
					fetchVarieties(user_id.value);
				});
			}

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setProd(n: 0) {
      prod.value = n;
    }

			onMounted(() => {
				fetchFarms();
			});

			watch(
				() => week.value.end,
				(v: any) => {
					fetchVarieties(user_id.value);
				}
			);

			function updateForm() {
				fetchVarieties(user_id.value);
			}

			return {
				fetchVarieties,
				processing,
				search,
				searchVarieties,
				varieties,
				savePicks,
				created,
				assignment,
				updateAssignment,
				updated,
				deleteAssignment,
				fD,
				today,
				week,
				masks,
				getDates,
				setDay,
				day,
				date,
				form,
				farm,
				farms,
				farmName,
				customers,
				customer,
				user_id,
				updateForm,
      prod,
      setProd
			};
		},
	});
</script>

